<template>
  <div class="product_container">
    <div @click="goBack" class="back_btn">返回</div>
    <div class="drop_box">
      <div
        ref="tab"
        :class="['tab_box', activeTab === item.label ? 'active' : '']"
        @click="showProducts(item)"
        v-for="(item, index) in btn_list" :key="index"
      >
        <img v-if="index === 1" src="@/assets/img/icon_fresh.png"/>
        <img v-else-if="index === 2" src="@/assets/img/icon_drink.png"/>
        <img v-else-if="index === 3" src="@/assets/img/icon_tea.png"/>
        <img v-else-if="index === 4" src="@/assets/img/icon_food.png"/>
        <img v-else-if="index === 5" src="@/assets/img/icon_life.png"/>
        <span>{{item.label}}</span>
      </div>
    </div>
    <div class="title">{{title}}</div>
    <div class="intro">{{intro}}</div>
    <!-- <div class="title">民族特色分类标题</div> -->
    <div class="card_list">
      <el-card class="card" v-for="item in articleList" :key="item.id">
        <div class="img_content" :style="{backgroundImage:'url(' + item.cover + ')'}" @click="goDetail(item.id)">
          <!-- <img :src="item.cover" alt=""> -->
        </div>
        <div class="text_content" @click="goDetail(item.id)">
          <div class="title">{{item.title}}</div>
          <div class="dec">{{item.describes}}</div>
        </div>
      </el-card>
    </div>
    <div class="pagination_container">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page.sync="page"
        :page-size="limit"
        layout="prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
    <el-backtop style="width:1rem;height:2rem" :right="10" :bottom="50">
      <div style="height: 100%;width: 100%;">
        <img style="width:100%;height:100%" src="@/assets/img/toTop.png" />
        <div style="text-align:center" class="goTop">回顶部</div>
      </div>
    </el-backtop>
  </div>
</template>

<script>

export default {
  name: 'village',
  data() {
    return {
      pageOption: {
        page: 1,
        size: 12,
        total: 38
      },
      categoryId: 19,
      page: 1,
      limit: 9,
      item: null,
      articleList: [],
      activeTab: '全部',
      btn_list: [
        { label: '全部', value: 19 },
        { label: '生鲜/蘑菇', value: 22 },
        { label: '酒水/饮料', value: 23 },
        { label: '茶叶/零食', value: 24 },
        { label: '调料/速食', value: 25 },
        { label: '居家/日用', value: 26 }
      ],
      title: '民族特色分类标题',
      intro: '由于地质条件、气候因素以及丰富深厚的人文历史渊源、多民族杂居等原因，造就了四川民族地区旅游资源类型多样，自然景观与人文景观相得益彰的特点。自然风光呈现高山峡谷、陡崖瀑布、多江并流的景观，而人文风光则体现了蜀地悠久的历史和发达的文化，名人荟萃，文物古迹遍布。我们将带领大家一同探索四川民族地区的美景和风情。'
    }
  },
  methods: {
    // 跳转至详情页
    goDetail(val) {
      this.$router.push({ path: '/detail', query: { id: val } })
    },
    // 获取栏目列表
    getCategory(val) {
      let url = ''
      if (val && val > 19) {
        url = '/api/web_index/feature?categoryId=19&subCategoryId=' + val + '&page=' + this.page + '&limit=' + this.limit
      } else {
        url = '/api/web_index/feature?categoryId=19' + '&page=' + this.page + '&limit=' + this.limit
      }
      this.$axios.get(url).then(res => {
        console.log('46--->', res.data)
        const a = res.data
        this.title = a.categoryName
        this.intro = a.describes
        this.articleList = a.documentsPage.records
        this.total = a.documentsPage.total
      })
    },
    changeActive(e, index) {
      const a = this.$refs.tab
      // a[0].classList.add('btn_active')
      if(index !== 0) {
        a[0].classList.remove('btn_active')
      }
      console.log(e)
      e.currentTarget.className = 'btn_active'
      // console.log("鼠标移入了")
    },
    removeActive(e, index) {
      e.currentTarget.className = 'tab_box'
      const a = this.$refs.tab
      a[0].classList.add('btn_active')
      // console.log("鼠标移出了")
    },
    goBack() {
      this.$router.push({ path: '/' })
    },
    handleCurrentChange() {
      this.getCategory(this.item == null ? null : this.item.value)
    },
    showProducts(item) {
      this.item = item
      this.page = 1
      this.activeTab = item.label
      this.getCategory(item.value)
    }
  },
  mounted() {
    const a = this.$refs.tab
    a[0].classList.add('btn_active')
    this.categoryId = this.$route.query.categoryId
    this.getCategory()
  }
}
</script>

<style lang="scss">
@import 'index.scss';
.product_container{
  .el-backtop {
    background-color: transparent;
    box-shadow: none;
  }
  .drop_box {
    position: absolute;
    top: 50px;
    right: 20px;
    width: 120px;
    background: #ffffff;
    border-radius: 20px;
    box-shadow: 2px 3px 13px -2px rgba(0,0,0,0.14);
    display: flex;
    flex-direction: column;
    .tab_box {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      // line-height: 40px;
      white-space: nowrap;
      cursor: pointer;
      color: #fff;
      border-bottom: 1px solid #dcdfe6;
      -webkit-appearance: none;
      text-align: center;
      box-sizing: border-box;
      outline: none;
      margin: 0;
      transition: .1s;
      font-weight: 500;
      // padding: 12px 0;
      width: 120px;
      font-size: 14px;
      font-family: 'SimHei';
      color: #333333;
      &:last-child{
        border-bottom: 0;
      }
      &.active,
      &:hover{
        background: linear-gradient(180deg,#77d366, #36b374) !important;
        color: #fff !important;
        border-radius: 20px;
        // box-shadow: 2px 3px 13px -2px rgba(0,0,0,0.14);
      }
      img{
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
    }
  }
}
@media screen and (min-width: 1600px) {
  .img_content{
    height: 5rem;
  }
}
@media screen and (max-width: 600px) {
  .product_container .title {
    font-size: 0.4rem !important;
    margin-bottom: 0.4rem !important;
  }
  .product_container .card_list .card {
    width: 100%;
    margin-right: 0;
  }
}
</style>
